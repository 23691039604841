

class CheckForm {


  constructor(options = {}) {

    let defaults = {
      form: '.form-standard'
    };

    // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
    this.settings = $.extend({}, defaults, options);

    // evenements par utilisateur ex scroll hover clic touch
    this.bindUI();
  }



  bindUI() {
    let self = this;
    let $error = $(self.settings.form).find('.msg-form-error');
    if($error.length >= 1) {
      self.generateId($error);
      self.goToError();
    }

  }
  generateId(error) {
    const self = this;
    let compt = 1
    $.each(error, function(){
      $(this).attr('id', 'msg-error-' + compt);
      compt++;
    });
  }
  goToError() {
    const self = this;
    let url = window.location.href;
    let tab = url.split("#");
    console.log(tab);
    url = tab[0] + '#msg-error-1';
    window.location.href = url;
  }
}


module.exports = CheckForm;
