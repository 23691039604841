

class FilterWares {


  constructor(options = {}) {

    let defaults = {
        container: '.filter-wares',
        item: '.form-wares-item',
        btn: '.form-wares-item-top',
        activeClass: 'as--visible'
    };

    // fusionne les options renseignees avec celles par defaut pour creer l'objet settings
    this.settings = $.extend({}, defaults, options);

    // evenements par utilisateur ex scroll hover clic touch
    this.bindUI();
  }



  bindUI() {
    let self = this;
    let $btn = $(self.settings.container).find(self.settings.btn);

    $btn.on('click touch', function(){
      let $parent = $(this).closest(self.settings.item);
      if($parent.hasClass(self.settings.activeClass)) {
        $parent.removeClass(self.settings.activeClass);
      } else {
        $parent.addClass(self.settings.activeClass);
      }
    });
  }

}


module.exports = FilterWares;
