'use strict';

/*global Modernizr */

import FilterWares from './class/FilterWares';
import CheckForm from './class/CheckForm';

const app = {
  init: function () {
    this.bindUI();

    if($('.filter-wares').length) {
      let filterWares = new FilterWares();
    }
    if($('.form-standard').length) {
      let checkForm = new CheckForm();
    }
  },
  bindUI: function () {
    let self = this;
    let wWidth;
    let wHeight;
    let currentWidth;
    let currentHeight;
    let timerResponsive;
    let wScroll;

    /*--- slider multiple changeant de nombre selon la taille - 1/3 ---*/
    // variables globales a definir en haut de scripts.js
    let numSlider = 4;
    let slider;
    if($('.cta-headline').length) {
      self.headlineAction();
    }
    if($('.nav-sidebar').length) {
      self.navSidebarAction();
    }
    $(function() {


      wScroll = $(window).scrollTop();

      /*================== INITIALISATIONS ==========================*/

      /*================== FONCTIONS GENERALES ==========================*/

      function viewport() {
        var e = window, a = 'inner';
        if (!('innerWidth' in window)) {
          a = 'client';
          e = document.documentElement || document.body;
        }
        return {
          width: e[ a + 'Width' ],
          height: e[ a + 'Height' ]
        };
      }
      /*--- fonctions generales ---*/
      function resetHeight(className) {
        $(className).css('min-height', '');
      }
      /*--- scroll tableaux qui debordent - 2/2 ---*/
      function scrollTable(className) {
        $(className + ' table').each(function() {
          var table = $(this);
          var container = $('<div class="table-scroll"></div>');
          $(table).before(container);
          $(container).append(table);
        });
      }
      /*================== FONCTIONS SPECIFIQUES AU PROJET ==========================*/
      /*--- slider multiple changeant de nombre selon la taille - 3/3 ---*/
      function initSlider(num) {
        if ($('.item-product').length > num) {
          var w = $('.list-product').width() / num;
          slider = $('.list-product').bxSlider({
            minSlides: num,
            maxSlides: num,
            slideWidth: w,
            speed: 700,
            pause: 7000,
            controls: true,
            auto: false,
            autoControls: false,
            autoControlsCombine: false,
            pager: false,
            touchEnabled: true,
            moveSlides: 1
          });
        }
      }
      function reloadSlider(num) {
        if ($('.item-product').length > num) {
          var w = $('.list-product').width() / num;
          slider.reloadSlider({
            minSlides: num,
            maxSlides: num,
            slideWidth: w,
            speed: 700,
            pause: 7000,
            controls: true,
            auto: false,
            autoControls: false,
            autoControlsCombine: false,
            pager: false,
            touchEnabled: true,
            moveSlides: 1
          });
        }
      }
      function imgWares(elem) {
        $(elem).each(function() {
          var elem = $('img', (this));
          $(elem).imagesLoaded(function() {
            var h = elem.height();
            var w = elem.width();
            $(elem).css('margin-top', -h / 2);
            $(elem).css('margin-left', -w / 2);
            setTimeout(function() {
              $(elem).removeClass('faded');
              $(elem).addClass('no-icon');
            }, 500);
          });
        });

      }
      function harmonizeHeight(className, includePadding) {
        /*--- mettre plusieurs blocs à la même hauteur ---*/
        if (typeof includePadding === 'undefined') {
          includePadding = true;
        }
        var targetH = 0;
        resetHeight(className);
        $(className).each(function() {
          var elem = $(this);
          var h = $(elem).height();
          if (includePadding === true) {
            var pT = parseInt($(elem).css('padding-top'));
            if (isNaN(pT)) {
              pT = 0;
            }
            var pB = parseInt($(elem).css('padding-bottom'));
            if (isNaN(pB)) {
              pB = 0;
            }
            h = $(elem).height() + pT + pB;
          }
          if (h > targetH) {
            targetH = h;
          }
        });
        targetH = Math.ceil(targetH);
        $(className).css('min-height', targetH + 'px');
      }
      /*--- scroll tableaux qui debordent - 1/2 ---*/
      scrollTable('.editor-wysiwyg');


      /*================== GENERAL ==========================*/

      /*--- initialisation des tailles de viewport ---*/
      currentWidth = wWidth = viewport().width;
      currentHeight = wHeight = viewport().height;

      /*--- ouverture sous-navigation ---*/

      $('.nav-principal-level-1').on('click touch', '.item-nav-principal-level-1', function(event) {
        event.stopImmediatePropagation();
        var elem = event.target;
        if (event.type === 'touchstart' && $(this).is('.open') &&
          !$(elem).is('.nav-principal-level-2') &&
          $(elem).closest('.nav-principal-level-2').length === 0) {

          $('.item-nav-principal-level-1.open').removeClass('open');
        } else {
          $('.item-nav-principal-level-1.open').removeClass('open');
          $(this).addClass('open');
        }

        if ($(elem).is('.cta-nav-principal-level-1') || $(elem).closest('.cta-nav-principal-level-1').length > 0) {
          return false;
        }
      });
      $('.nav-principal-level-1').on('mouseleave', function() {
        $('.item-nav-principal-level-1.open').removeClass('open');
      });

      $('.nav-principal-level-2').on('click', '.item-nav-principal-level-2', function(event) {
        event.stopImmediatePropagation();
        var elem = event.target;
        if ($(this).find('.nav-principal-level-3').length > 0) {
          if (event.type === 'touchstart' &&
            $(this).is('.open') &&
            !$(elem).is('.item-nav-principal-level-3') &&
            $(elem).closest('.item-nav-principal-level-3').length === 0) {

            $('.item-nav-principal-level-2.open').removeClass('open');
          } else {
            $('.item-nav-principal-level-2.open').removeClass('open');
            $(this).addClass('open');
          }

          if ($(elem).is('.cta-nav-principal-level-2') || $(elem).closest('.cta-nav-principal-level-2').length > 0) {
            return false;
          }
        }

      });
      $('.nav-principal-level-2').on('mouseleave', function() {
        $('.item-nav-principal-level-2.open').removeClass('open');
      });

      if ($('.nav-sidebar-level-1').length > 0) {
        $('.item-nav-sidebar-level-1').each(function() {
          if ($(this).hasClass('active')) {
            $('.item-nav-sidebar-level-1').addClass('fade');
            $(this).removeClass('fade');
          }
          if ($(this).find('.nav-sidebar-level-2').length > 0) {
            $(this).addClass('has-level');
          }
        });
      }

      /*--- ouverture member ---*/
      $('.connected').on('mouseenter focusin touchstart', function() {
        var elem = $(this).closest('.connected');
        $(elem).addClass('open');
      });
      $('.connected').on('mouseleave', function() {
        $(this).removeClass('open');
      });

      /*--- creation et ouverture menu petits ecrans ---*/
      $('.list-nav-principal-level-1').clone().appendTo('.body-side');

      $('.cta-nav-resp').on('click', function() {
        $('#body').toggleClass('with-menu');
      });

      /*--- navigation side ---*/
      if (wWidth > 500) {
        $('#body').removeClass('with-menu');
        $('.body-side .item-nav-principal-level-1.open').removeClass('open');
      }
      if (wWidth > 672) {
        $('.product-slider, .item-product').removeClass('active');
      }

      /*--- ouverture navigation resp ---*/
      $('.body-side').on('click', '.item-nav-principal-level-1', function(event) {
        event.stopImmediatePropagation();
        var elem = event.target;
        /*if ($(this).is('.open') && !$(elem).is('.item-nav-principal-level-2') && $(elem).closest('.item-nav-principal-level-2').length === 0) {
         $(this).removeClass('open');
         } else {
         $('.item-nav-principal-level-1.open').removeClass('open');
         $(this).addClass('open');
         }*/
        $(this).toggleClass('open');
        if (($(elem).is('.cta-nav-principal-level-1') || $(elem).closest('.cta-nav-principal-level-1').length > 0)) {
          return false;
        }
      });
      $('.body-side').on('click', '.item-nav-principal-level-2', function(event) {
        event.stopImmediatePropagation();
        var elem = event.target;
        if ($(this).find('.nav-principal-level-3').length > 0) {
          if ($(this).is('.open') && !$(elem).is('.item-nav-principal-level-3') && $(elem).closest('.item-nav-principal-level-3').length === 0) {
            $(this).removeClass('open');
          } else {
            $('.item-nav-principal-level-2.open').removeClass('open');
            $(this).addClass('open');
          }
          if (($(elem).is('.cta-nav-principal-level-2') || $(elem).closest('.cta-nav-principal-level-2').length > 0)) {
            return false;
          }
        }
      });
      /*--- clics sur le body = enlever le focus ailleurs ---*/
      $('#body').on('click focusin', function(event) {
        var elem = event.target;
        if ($(elem).closest('.nav-principal').length === 0 &&
          !$(elem).is('.nav-principal') &&
          $(elem).closest('.body-side').length === 0 &&
          !$(elem).is('.body-side')) {

          $('.nav-principal .menu > .menu-item.open').removeClass('open');
        }
        if ($(elem).closest('.body-side').length === 0 &&
          !$(elem).is('.body-side') &&
          $(elem).closest('.cta-nav-resp').length === 0 &&
          !$(elem).is('.cta-nav-resp')) {

          $('#body').removeClass('with-menu');
        }
        if ($(elem).closest('.skip').length === 0 && !$(elem).is('.skip')) {
          $('.skip').removeClass('focused');
        }
        if ($(elem).closest('.search-header').length === 0 && !$(elem).is('.search-header')) {
          $('.search-header').removeClass('active');
        }
      });

      /*--- liens d'accès rapide ---*/
      $('.skip').on('focusin', function() {
        $('.skip').addClass('focused');
      });

      /*--- clic search ---*/
      $('.search-header').on('click', '.trigger-more-search', function() {
        var elem = $(this).closest('.search-header');
        $(elem).toggleClass('active');
      });

      /*--- clic process ---*/
      $('.block-process').on('click', '.top-block-process', function() {
        var elem = $(this).closest('.block-process');
        var btnprocess = $(this).find('.info-block-process');
        $(elem).toggleClass('active');
        if($(elem).hasClass('active')) {
          btnprocess.html('close');
        } else {
          btnprocess.html('open');
        }
      });

      /*--- trigger addthis ---*/
      $('.share').on('click', '.cta-share', function(event) {
        event.preventDefault();
        var id = $(this).attr('data-share');
        $(this).closest('.share').find('.at-svc-' + id + ' span').trigger('click');
      });

      /*--- control block detail ---*/
      $('.product-slider .main-product').on('click', '.block-product', function() {
        var elem = $(this).closest('.item-product');
        $(elem).toggleClass('active');
        if (wWidth > 800) {
          var elemData = $(elem).attr('data-theme');
          var contentProduct = $(this).parent().html();
          var contentDetail = $(elem).find('.block-detail').parent().html();
          $('.case').addClass('active');
          $('.product-slider').addClass('active');
          $('#my-block-case').addClass(elemData);
          $(contentProduct).clone().appendTo('#my-block-case .side-block-case');
          $(contentDetail).clone().appendTo('#my-block-case .main-block-case');
          harmonizeHeight('#my-block-case .main-block-case, #my-block-case .side-block-case');
        }
        return false;

      });

      $('.case').on('click', '.cta-block-case', function() {
        var elem = $(this).closest('.case');
        $(elem).removeClass('active');
        $('.item-product.active').removeClass('.active');
        $('#my-block-case').attr('class', '');
        $('.product-slider').removeClass('active');
        $('#my-block-case .side-block-case').empty();
        $('#my-block-case .main-block-case').empty();
        resetHeight('#my-block-case .main-block-case, #my-block-case .side-block-case');
        return false;
      });

      if ($('.action .doc-mention .item-action').length === 1) {
        $('.action .doc-mention .item-action').addClass('full-item-action');
      }

      /*--- suggestion scroll doux pour liens ancres ---*/
      $('#body').on('click', 'a[href^="#"]:not([href="#"])', function() {
        var target = $(this).attr('href');
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 300, 'easeInOutQuad', function() {
          $(target).focus(); // mettre un tabindex="-1" sur la cible si c'est un element qui ne recoit pas le focus par defaut
        });
        return false;
      });

      /*--- activer la colorbox ---*/
      if (wWidth > 560) {
        $('.cta-popin').colorbox({
          href: function() {
            var url = $(this).attr('href') + ' #main-content';
            return url;
          },
          close: 'Fermer',
          maxWidth: '90%',
          maxHeight: '90%'
        });
        $.colorbox.resize();
      } else {
        $.colorbox.remove();
      }


      /*--- uniform ---*/
      $('.form-standard input[type="checkbox"]').uniform();
      $('.form-standard input[type="radio"]').uniform();
      $('.form-standard select').uniform();




      function makeResponsive() {
        imgWares('.media-wares');

        /*--- navigation side ---*/
        if (wWidth > 600) {
          $('#body.with-menu').removeClass('with-menu');
          $('.body-side .item-nav-principal-level-1.open').removeClass('open');
        }

        /*--- harmonizeHeight et reset ---*/
        $('.object').each(function() {
          if (!$(this).hasClass('object-lead')) {
            var elem = $(this).find('.cta-object');
            if (wWidth > 512) {
              harmonizeHeight(elem);
            } else {
              resetHeight(elem);
            }
          }
        });
        $('.list-ticket').each(function() {
          var elem = $(this).find('.content-block-ticket');
          if (wWidth > 800) {
            harmonizeHeight(elem);
          } else {
            resetHeight(elem);
          }
        });
        $('.list-unit').each(function() {
          var elem = $(this).find('.main-block-unit');
          if (wWidth > 800) {
           setTimeout(function() {
               harmonizeHeight(elem);
           },1000);
          } else {
            resetHeight(elem);
          }
        });

        if (wWidth > 800) {
          harmonizeHeight('.item-world .list-nav-principal-level-2, .item-world .side-nav-principal');
          harmonizeHeight('.block-newspaper, .block-author');
          harmonizeHeight('.block-partner, .block-journal');
          //harmonizeHeight('.block-presentation, .block-master');
          harmonizeHeight('.main-block-case, .side-block-case');
          harmonizeHeight('.block-contributor');
          harmonizeHeight('.block-exemple');
          harmonizeHeight('.wrap-main-sheet, .wrap-side-sheet');
          harmonizeHeight('.block-unit');
          harmonizeHeight('.register.inside-space, .form-login');
          harmonizeHeight('.text-block-contract');
          harmonizeHeight('.title-block-unit');
        } else {
          resetHeight('.item-world .list-nav-principal-level-2, .item-world .side-nav-principal');
          resetHeight('.block-newspaper, .block-author');
          resetHeight('.block-partner, .block-journal');
          resetHeight('.block-presentation, .block-master');
          resetHeight('.main-block-case, .side-block-case');
          resetHeight('.block-contributor');
          resetHeight('.block-exemple');
          resetHeight('.wrap-main-sheet, .wrap-side-sheet');
          resetHeight('.block-unit');
          resetHeight('.register.inside-space, .form-login');
          resetHeight('.text-block-contract');
          resetHeight('.title-block-unit');
        }
        if(wWidth > 512) {
          harmonizeHeight('.block-contract');
          harmonizeHeight('.main-block-contract');
        } else {
          resetHeight('.block-contract');
          resetHeight('.main-block-contract');
        }
        /* clean case */
        if (wWidth > 800) {
          $('.item-product.active').removeClass('active');
          $('.case.active').removeClass('active');
          $('#my-block-case').attr('class', '');
          $('.product-slider.active').removeClass('active');
          $('#my-block-case .side-block-case').empty();
          $('#my-block-case .main-block-case').empty();
          resetHeight('#my-block-case .main-block-case, #my-block-case .side-block-case');
        }

        /*--- slider multiple changeant de nombre selon la taille - 2/3 ---*/
        // penser a changer le nom des variables : ex newNumSlider > newNumSliderGallery
        if (wWidth > 800) {
          var newNumSlider = 1;
          if (wWidth > 1216) {
            newNumSlider = 4;
          } else if (wWidth > 800) {
            newNumSlider = 3;
          }
          $('.list-product').imagesLoaded(function() {
            if (typeof slider === 'undefined') {
              initSlider(newNumSlider);
            } else if (newNumSlider === numSlider) {
              reloadSlider(newNumSlider);
            } else {
              slider.destroySlider();
              initSlider(newNumSlider);
            }
          });
          numSlider = newNumSlider;
        } else {
          if (typeof slider !== 'undefined') {
            slider.destroySlider();
          }

        }
      }
      /*================== ACTIONS AU RESIZE ET SCROLL ==========================*/

      /*--- fonctions dependant de resolution d'ecran (si reconnait media queries) ---*/
      if (Modernizr.mq('only all')) {


        $(window).on('resize', function() {
          wWidth = viewport().width;
          wHeight = viewport().height;
          if (currentHeight !== wHeight || currentWidth !== wWidth) {
            currentHeight = wHeight;
            currentWidth = wWidth;
            /*--- timer pour le redimensionnement d'ecran ---*/
            clearTimeout(timerResponsive);
            timerResponsive = setTimeout(makeResponsive, 300);
          }

        });
      }

      makeResponsive();


      /*--- fonctions au scroll ---*/
      $(window).on('scroll', function() {
        wScroll = $(window).scrollTop();
      });

      if($('.need').length) {
        let $needI = $('.need-item');
        let paddingItem = 5.2;
        if($needI.length > 6 && $needI.length < 13) {
            switch($needI.length) {
              case(7):
                paddingItem = 4.2;
                break;
              case(8):
                paddingItem = 3.4;
                break;
              case(9):
                paddingItem = 2.8;
                break;
              case(10):
                paddingItem = 2.3;
                break;
              case(11):
                paddingItem = 1.9;
                break;
              case(12):
                paddingItem = 1.9;
                break;
            }
        } else {
          paddingItem = 1.5901;
        }
        if($needI.length > 6) {
          let newPadding = paddingItem + '%' + ' 0.375rem';
          $.each($needI, function(){
            $(this).find('a').css('padding', newPadding);
          });
        }

      }
      /*================== ACTIONS SPECIFIQUES OLD IE ==========================*/

    });


   /* document.onreadystatechange = function() {
      if (document.readyState === 'complete') {
        makeResponsive();
      }
    };*/

  },
  headlineAction: function() {
    const self = this;
    let $headlineCta = $('.cta-headline');
    let $relation = $('.relation');
    let $close = $('.relation-cta.as--type-close');
    $headlineCta.on('click touch', function(){
      $relation.addClass('as--active');
    });
    $close.on('click touch', function(){
      if($relation.hasClass('as--active')) {
        $relation.removeClass('as--active');
      }
    });
  },
  navSidebarAction: function() {
    const self = this;
    let $item = $('.nav-sidebar').find('.item-nav-sidebar-level-2');
    $.each($item, function(){
      if($(this).hasClass('active')) {
        $(this).closest('.item-nav-sidebar-level-1').addClass('active');
      }
    });
  }
};

app.init();

